import React, { useEffect, useState } from "react";

import { Row, Form, Col, Divider, Avatar, Spin } from "antd";
import { UserRound } from "lucide-react";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { notificationErroInternal } from "util/api-utils";
import { deletePlanning, getPlanningUserById } from "services/planning/planning-service";
import { ListEmptyState } from "views/util/emptyState";
import PlanningDetails from "./planning-details";
dayjs.extend(customParseFormat);

const FormUserPlanningDetails = (props) => {
  const [loadingData, setLoadingData] = useState(false);
  const [imgAvatar, setImgAvatar] = useState("");
  const [form] = Form.useForm();
  const [data, setData] = useState([]);

  useEffect(() => {
    setImgAvatar(props.user?.img);
    getData();
  }, [props, setLoadingData]);

  function getData() {
    if (props.user?.id) {
      setLoadingData(true);
      getPlanningUserById(props.user?.id)
        .then((res) => {
          setData(res);
          setLoadingData(false);
        })
        .catch((err) => {
          setLoadingData(false);
          notificationErroInternal(
            "Aconteceu um erro inesperado, por favor, tente mais tarde."
          );
        });
    }
  }
  function deletePlanningDetails(id) {
    setLoadingData(true);
    deletePlanning(id)
      .then(() => {
        getData();
      })
      .catch((err) => {
        setLoadingData(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  return (
    <>
      <Spin size="large" spinning={loadingData}>
        <Form form={form} layout="vertical">
          <Row
            gutter={16}
            style={{ textAlign: "center", marginBottom: "30px" }}
          >
            <Col span={24}>
              <Avatar
                src={imgAvatar}
                icon={<UserRound size={120} />}
                size={120}
              />
            </Col>
          </Row>
          <Divider />
          {data.length > 0 ? (
            <PlanningDetails
              data={data}
              canDelete={true}
              deletePlanning={deletePlanningDetails}
            />
          ) : (
            <ListEmptyState subTitle="Aluno sem histórico de ciclo" />
          )}
        </Form>
      </Spin>
    </>
  );
};
export default FormUserPlanningDetails;
