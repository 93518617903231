import React, { useEffect, useState } from "react";
import {
  Spin,
  Button,
  Row,
  Input,
  Space,
  Card,
  Table,
  Typography,
  Form,
  Select,
  Col,
  Modal,
  message,
  Upload,
  Avatar,
  Tooltip,
  DatePicker
} from "antd";
import {
  ArrowRight,
  CircleAlert,
  Search,
  UserRound,
  UserRoundPlus,
  Users,
} from "lucide-react";
import { selectRole } from "reducer/login/login-user";
import { useSelector } from "react-redux";
import { FONT, USER_STORAGE, BASE_URL } from "constants/index";
import { notificationErroInternal, notificationInfo, notificationSucess } from "util/api-utils";
import { ColumnsUser } from "./columns-table";
import {
  deleteUser,
  getUsers,
  putUser,
  saveUser,
} from "services/user/user-service";
import DrawerApp from "components/drawer-app";
import { LoadingOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);
const { Text } = Typography;
const { confirm } = Modal;
const dateFormat = "DD/MM/YYYY";

const UsersClient = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(null);
  const [roleRedux, setRoleRedux] = useState(useSelector(selectRole));
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const formRef = React.useRef(null);
  const [selectPerfil, setSelectPerfil] = useState("USER");
  const [editId, setEditId] = useState(null);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [imgAvatar, setImgAvatar] = useState("");
  const [typeImg, setTypeImg] = useState("");
  const [changeImage, setChangeImage] = useState(false);

  useEffect(() => {
    const localStorageItem = localStorage.getItem(USER_STORAGE);
    if (localStorageItem != null) {
      const { role } = JSON.parse(localStorageItem);
      if (roleRedux == "" || role != roleRedux) {
        setRoleRedux(role);
      }
    }
  }, []);

  useEffect(() => {
    refreshData();
  }, []);

  function refreshData() {
    setLoading(true);
    getData();
  }

  const handleOk = () => {
    formRef.current
      .validateFields()
      .then((values) => {
        setLoading(true);
        let functionSave = saveUser;
        values.img = imgAvatar;
        values.typeImage = typeImg;
        values.changeImage = changeImage;
        values.birthDate = values.birthDate.format(dateFormat);

        if (editId !== null) {
          functionSave = putUser;
          values.id = editId;
        }
        functionSave(values)
          .then(() => {
            notificationSucess();
            formRef.current?.resetFields();
            refreshData();
            onClose();
          })
          .catch((err) => {
            const errorParse = JSON.parse(err.message)
            setLoading(false);
            if (errorParse.status == 406){
              notificationInfo(
                "E-mail já cadastrado"
              );
            }else{
              onClose();
              formRef.current?.resetFields();
              notificationErroInternal(
                "Aconteceu um erro inesperado, por favor, tente mais tarde."
              );
            }
           
          });
      })
      .catch((errors) => {
        console.log(errors);
      });
  };
  function getData() {
    let obj = {
      search: search,
    };

    if (search == null || search == "") {
      obj = {};
    }
    getUsers(obj)
      .then((res) => {
        res = res.map((obj) => ({
          ...obj,
          disable: disable,
          edit: edit,
          profile:
            obj.role == "ADMIN"
              ? "Administrador"
              : obj.role == "USER_ADMIN"
              ? "Professor"
              : "Aluno",
        }));
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    formRef.current?.resetFields();
    setEditId(null);
    setImgAvatar("");
    setChangeImage(false);
    setTypeImg("");
    setOpen(false);
  };
  function edit(row) {
    setEditId(row.id);
    setImgAvatar(row.img);
    setSelectPerfil(row.role);
    setTimeout(() => {
      formRef.current?.setFieldsValue({
        name: row.name,
        email: row.email,
        tipeUser: row.role,
        gender: row.gender,
        birthDate: row.birthDate
        ? dayjs(row.birthDate)
        : null,
      });
    }, 300);

    showDrawer();
  }
  function disable(row) {
    confirm({
      title: (
        <div>
          <CircleAlert size={22} style={{ marginBottom: "-5px" }} />
          <span style={{ marginLeft: "10px", fontFamily: FONT }}>
            Tem certeza que deseja deletar o usuário '{row.name}' ?
          </span>
        </div>
      ),
      icon: null,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        setLoading(true);
        deleteUser(row.id)
          .then(() => {
            notificationSucess();
            refreshData();
          })
          .catch((err) => {
            setLoading(false);
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
          });
      },
      onCancel() {},
    });
  }

  function callBackAvatar(imageUrl) {
    setLoadingAvatar(false);
    setImgAvatar(imageUrl);
    setChangeImage(true);
  }
  function handleChangeAvatar(info) {
    if (info.file.status === "uploading") {
      setLoadingAvatar(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj, (imageUrl) =>
        callBackAvatar(imageUrl)
      );
    }
    if (info.file.status === "error") {
      setLoadingAvatar(false);
      getBase64(info.file.originFileObj, (imageUrl) =>
        callBackAvatar(imageUrl)
      );
    }
  }
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

    if (file.type === "image/jpeg") {
      setTypeImg("jpeg");
    } else if (file.type === "image/png") {
      setTypeImg("png");
    }
    if (!isJpgOrPng) {
      message.error("Você pode enviar apenas imagens JPG/PNG");
    }
    const isLt16M = file.size / 1024 / 1024 < 16;
    if (!isLt16M) {
    //  message.error("A imagem tem limite de 16MB!");
    }
    return isJpgOrPng
  }

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const formNewUser = () => {
    return (
      <>
        <Form ref={formRef} layout="vertical">
          <Row
            gutter={16}
            style={{ textAlign: "center", marginBottom: "30px" }}
          >
            <Col span={24}>
              <Upload
                customRequest={dummyRequest}
                beforeUpload={beforeUpload}
                onChange={handleChangeAvatar}
                showUploadList={false}
              >
                {loadingAvatar ? (
                  <LoadingOutlined />
                ) : (
                  <Avatar
                    src={imgAvatar}
                    icon={<UserRound size={120} />}
                    size={120}
                  />
                )}
              </Upload>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label={<span style={{ fontFamily: FONT }}>Nome</span>}
                rules={[
                  { required: true, message: "É necessário informar o nome" },
                ]}
              >
                <Input style={{ fontFamily: FONT }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="tipeUser"
                label={<span style={{ fontFamily: FONT }}>Perfil</span>}
                style={{ height: "40px" }}
                rules={[
                  { required: true, message: "É necessário informar o perfil" },
                ]}
              >
                <Select
                  allowClear
                  onChange={(event) => {
                    setSelectPerfil(event);
                  }}
                >
                  {roleRedux == "ADMIN" && (
                    <>
                      <Option value="ADMIN">Administrador</Option>
                      <Option value="USER">Aluno</Option>
                      <Option value="USER_ADMIN">Professor</Option>
                    </>
                  )}
                  {roleRedux == "USER_ADMIN" && (
                    <>
                      <Option value="USER">Aluno</Option>
                    </>
                  )}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>

          <Col span={12}>
              <Form.Item
                name="gender"
                label={<span style={{ fontFamily: FONT }}>Sexo</span>}
                style={{ height: "40px" }}
                rules={[
                  { required: true, message: "É necessário informar o sexo" },
                ]}
              >
                <Select
                  allowClear
                  onChange={(event) => {
                    setSelectPerfil(event);
                  }}
                >
                     <>
                      <Option value="MALE">Masculino</Option>
                      <Option value="FEMALE">Feminino</Option>
                  
                      </>
                </Select>
              </Form.Item>
            </Col>
        
          <Col span={12}>
              <Form.Item
                name="birthDate"
                label={
                  <span style={{ fontFamily: FONT }}>Data de Nascimento</span>
                }
                rules={[
                  {
                    required: true,
                    message: "É necessário informar a data de nascimento.",
                  },
                ]}
              >
                <DatePicker
                  style={{ fontFamily: FONT, width: "100%" }}
                  placeholder={""}
                  format={dateFormat}
                  suffixIcon={null}
                />
              </Form.Item>
            </Col>
            </Row>
          <Row gutter={16}>
            <Col span={editId != null ? 24 : 12}>
              <Form.Item
                name="email"
                label={<span style={{ fontFamily: FONT }}>E-mail</span>}
                rules={[
                  { required: true, message: "É necessário informar o e-mail" },
                ]}
              >
                <Input style={{ fontFamily: FONT }} />
              </Form.Item>
            </Col>
            {editId == null && (
              <Col span={12}>
                <Form.Item
                  name="password"
                  label={<span style={{ fontFamily: FONT }}>Senha</span>}
                  rules={[
                    {
                      required: true,
                      message: "É necessário informar a senha",
                    },
                  ]}
                >
                  <Input style={{ fontFamily: FONT }} />
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form>
      </>
    );
  };
  return (
    <div className="container-users-client">
      <Spin size="large" spinning={loading}>
        <Row gutter={24} style={{ padding: "10px" }}>
          <Space>
            <Input
              placeholder="Pesquisar Usuário"
              onChange={(value) => setSearch(value.target.value)}
              suffix={<Search size={18} color="#e0e0e0" />}
              style={{ height: "40px" }}
              onPressEnter={refreshData}
            />

            <Button
              style={{ fontFamily: FONT }}
              type="primary"
              icon={
                <ArrowRight
                  size={18}
                  style={{ marginBottom: "-3px", marginRight: "7px" }}
                />
              }
              onClick={refreshData}
            >
              Filtrar
            </Button>
          </Space>
        </Row>
        <Row gutter={[24, 24]} style={{ marginLeft: "0px" }}>
          <Space
            direction="vertical"
            size={"small"}
            style={{
              flex: "1 1 500px",
              minWidth: outerWidth < 501 ? "0px" : "500px",
            }}
          >
            <Card
              id="card-table"
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <Users
                      size={18}
                      style={{ marginBottom: "-4px", marginRight: "10px" }}
                    />
                    <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                      Usuários
                    </Text>
                  </div>
                  <Button
                    onClick={showDrawer}
                    style={{ fontFamily: FONT, marginLeft: "10px" }}
                  >
                    <Tooltip style={{ fontFamily: FONT }} title="Novo Usuário">
                      <UserRoundPlus size={20} />
                    </Tooltip>
                  </Button>
                </div>
              }
              bordered={false}
            >
              <Table
                columns={ColumnsUser(outerWidth)}
                dataSource={data}
                pagination={{ pageSize: 10, showSizeChanger: false }}
                locale={{
                  emptyText: "Sem registro",
                }}
              />
            </Card>
          </Space>
        </Row>
      </Spin>

      <DrawerApp
        open={open}
        onClose={onClose}
        title={"Novo Usuário"}
        body={formNewUser()}
        size={outerWidth < 501 ? "small" : "large"}
        actionSave={handleOk}
      />
    </div>
  );
};
export default UsersClient;
