export const GetPropsMenu = (path) => {
  var defaultSelectedKeys = "1";
  switch (path) {
    case "/app/client/managerial":
      defaultSelectedKeys = "1";
      break;
    case "/app/client/reviews":
      defaultSelectedKeys = "2";
      break;
    case "/app/client/planning":
      defaultSelectedKeys = "4";
      break;
    case "/app/client/load-control":
      defaultSelectedKeys = "5";
      break;
    case "/app/client/users":
      defaultSelectedKeys = "6";
      break;
    case "/app/client/ranking":
      defaultSelectedKeys = "7";
      break;
    default:
      break;
  }
  return { defaultSelectedKeys };
};
