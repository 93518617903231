import React, { useEffect, useState } from "react";

import { Row, Typography, Col, List, Modal, Collapse } from "antd";
import { FONT } from "constants/index";
import { CircleAlert, Delete } from "lucide-react";
import { translationsTypes } from "./columns-table";
const { Panel } = Collapse;

const { Text } = Typography;
const { confirm } = Modal;

const PlanningDetails = (props) => {
  const [data, setData] = useState([]);
  const [type, setType] = useState("");

  useEffect(() => {
    var dataValues = props.data;
    if (props.type) {
      dataValues = props.data.filter((item) => {
        return item.exercises.some((exercise) => exercise.type === props.type);
      });
    }
    setData(dataValues);
    setType(props.type);
  }, [props]);

  const DescriptionItem = ({ title, content }) => (
    <div className="site-description-item-profile-wrapper">
      <p
        className="site-description-item-profile-p-label"
        style={{ fontFamily: FONT }}
      >
        <b>{title}</b>:
      </p>
      {content}
    </div>
  );
  function deletePlanningItem(row) {
    confirm({
      title: (
        <div>
          <CircleAlert size={22} style={{ marginBottom: "-5px" }} />
          <span style={{ marginLeft: "10px", fontFamily: FONT }}>
            Tem certeza que deseja deletar o cíclo '{row.cycleName}' ?
          </span>
        </div>
      ),
      icon: null,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",
      onOk() {
        props.deletePlanning(row.id);
      },
      onCancel() {},
    });
  }
  function getAverage(item) {
    return (
      <>
        <Row>
          <Col span={24}>
            <DescriptionItem title="Semanas" content={item.week} />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <DescriptionItem title="Peso Mínimo" content={item.loadInitial} />
          </Col>
          <Col span={8}>
            <DescriptionItem title="VMP Mínimo" content={item.vmpInitial} />
          </Col>
          <Col span={8}>
            <DescriptionItem title="%1RM Mínimo" content={item.rmInitial} />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <DescriptionItem title="Peso Máximo" content={item.loadFinal} />
          </Col>
          <Col span={8}>
            <DescriptionItem title="VMP Máximo" content={item.vmpFinal} />
          </Col>
          <Col span={8}>
            <DescriptionItem title="%1RM Máximo" content={item.rmFinal} />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <DescriptionItem title="Peso Médio" content={item.loadAverage} />
          </Col>
          <Col span={8}>
            <DescriptionItem title="VMP Médio" content={item.vmpAverage} />
          </Col>
          <Col span={8}>
            <DescriptionItem title="%1RM Médio" content={item.rmAverage} />
          </Col>
        </Row>
      </>
    );
  }

  function getAverageHangCleanOrBarbell(item) {
    const name = item.type == "BARBELL_JUMP" ? "ALT(CM)" : "%1RM";

    return (
      <>
        <Row>
          <Col span={24}>
            <DescriptionItem title="Semanas" content={item.week} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Peso Mínimo" content={item.loadInitial} />
          </Col>

          <Col span={12}>
            <DescriptionItem
              title={name + " Mínimo"}
              content={item.rmInitial}
            />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Peso Máximo" content={item.loadFinal} />
          </Col>

          <Col span={12}>
            <DescriptionItem title={name + " Máximo"} content={item.rmFinal} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title="Peso Médio" content={item.loadAverage} />
          </Col>

          <Col span={12}>
            <DescriptionItem title={name + " Médio"} content={item.rmAverage} />
          </Col>
        </Row>
      </>
    );
  }
  function getAverageAdditionalExercise(item) {
    return (
      <>
        <Row>
          <Col span={24}>
            <DescriptionItem title="Semanas" content={item.week} />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <DescriptionItem title="Peso Mínimo" content={item.loadInitial} />
          </Col>
          <Col span={8}>
            <DescriptionItem title="Peso Médio" content={item.loadAverage} />
          </Col>

          <Col span={8}>
            <DescriptionItem title="Peso Máximo" content={item.loadFinal} />
          </Col>
        </Row>
      </>
    );
  }

  const ExercisePanels = ({ exercises }) => {
    if (!exercises) {
      return;
    }

    const defaultActive = props.canDelete ? "-1" : "0";
    var filter = Object.keys(translationsTypes);
    if (type != "" && type != undefined) {
      filter = [type];
    }
    return (
      <Collapse defaultActiveKey={defaultActive}>
        {exercises
          .filter((it) => filter.includes(it.type))
          .map((exercise, index) => {
            var translatedType =
              translationsTypes[exercise.type] || exercise.type;
            const additionalName = exercise.additionalName;
            if (additionalName) {
              translatedType = translatedType + " ( " + additionalName + " )";
            }
            return (
              <Panel
                header={translatedType}
                key={index}
                style={{ fontFamily: FONT }}
              >
                {exercise.type == "HANG_CLEAN" ||
                exercise.type == "BARBELL_JUMP"
                  ? getAverageHangCleanOrBarbell(exercise)
                  : exercise.type == "ADDITIONAL_EXERCISE"
                  ? getAverageAdditionalExercise(exercise)
                  : getAverage(exercise)}
              </Panel>
            );
          })}
      </Collapse>
    );
  };

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              title={
                <div
                  style={{ justifyContent: "space-between", display: "flex" }}
                >
                  <Text strong style={{ fontFamily: FONT }}>
                    {index == 0 ? "Ciclo Atual" : ""}
                  </Text>
                  {props.canDelete && (
                    <Delete
                      size={20}
                      onClick={() => deletePlanningItem(item)}
                      style={{
                        marginBottom: "-4px",
                        marginRight: "10px",
                        cursor: "pointer",
                        color: "#EC6D62",
                      }}
                    />
                  )}
                </div>
              }
              description={
                <>
                  <Row>
                    <Col span={24}>
                      <DescriptionItem title="Data" content={item.data} />
                    </Col>
                  </Row>
                  <ExercisePanels exercises={item.exercises} />
                </>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};
export default PlanningDetails;
