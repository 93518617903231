import React, { useEffect, useState } from "react";

import {
  Row,
  Typography,
  Form,
  Col,
  Spin,
  Space,
  Button,
  Select,
  Divider,
  InputNumber,
  Input,
  Table,
  Avatar,
  Card,
} from "antd";

import { FONT } from "constants/index";
import { notificationErroInternal, notificationInfo } from "util/api-utils";

import { v4 as uuidv4 } from "uuid";
import { ColumnsChart } from "./columns-table";
import { ArrowUpFromLine, UserRound } from "lucide-react";
import { chartBar } from "../reviews/reviews-drawer-charts";

const { Text } = Typography;

const ManagerialChart = (props) => {
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([]);
  const [planTraining, setPlanTraning] = useState(null);

  useEffect(() => {
    setLoadingData(true);
    console.log(props);
    
    const planTraining = props.user?.planningTrainings?.filter(
      (pt) => pt.planningType == "BACK_SQUAD"
    );
    setData(planTraining[0]?.allTrainings ?? []);
    setPlanTraning(planTraining[0])
    setLoadingData(false);
  }, [props]);

  function mountChart() {
    return data.map((item) => {
      const vmps = [item.vmp1, item.vmp2, item.vmp3, item.vmp4].filter(
        (item) => item != null
      );
      var vmpMax = null;
      if (vmps.length > 0) {
        vmpMax = Math.max(...vmps);
      }
      const vmpEsperada = planTraining[`vmp${item.load}`]
      return {
        load: "Semana - " + item.load,
        vmpEsperada:vmpEsperada,
        vmpAtual: vmpMax,
      };
    });
  }
  return (
    <>
      <Spin spinning={loadingData}>
        <Row gutter={16} style={{ textAlign: "center", marginBottom: "30px" }}>
          <Col span={24}>
            <Avatar
              src={props.user?.img}
              icon={<UserRound size={120} />}
              size={120}
            />
          </Col>
        </Row>
        <Table
          columns={ColumnsChart()}
          dataSource={data}
          size={"small"}
          pagination={{ pageSize: 5, showSizeChanger: false }}
          locale={{
            emptyText: "Sem registro",
          }}
        />
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={24} style={{ marginTop: "10px" }}>
            <Card
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <ArrowUpFromLine
                      size={18}
                      style={{ marginBottom: "-4px", marginRight: "10px" }}
                    />
                    <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                      Evolução VMP
                    </Text>
                  </div>
                </div>
              }
              style={{ width: "100%" }}
            >
              {chartBar(
                mountChart(),
                "load",
                "vmpEsperada",
                "vmpAtual",
                "VMP Esperada",
                "VMP Atual",
                10
              )}
            </Card>
          </Col>
        </Row>
      </Spin>
    </>
  );
};
export default ManagerialChart;
