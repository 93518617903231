import React, { useEffect, useState } from "react";

import { Row, Typography, Col, Divider, Card, Table } from "antd";
import { BicepsFlexed, Gauge, TrendingUpDown } from "lucide-react";

import { FONT } from "constants/index";

import { notificationInfo } from "util/api-utils";
import { ColumnsLastReview } from "./columns-table";
import { chartLiner } from "../reviews/reviews-drawer-charts";

const { Text } = Typography;

const FormNewUserPlanningBackSquad = (props) => {
  const [review, setReview] = useState(null);

  useEffect(() => {
    setReview(props.review);
  }, [props]);

  function getlastReview() {
    if (review?.actualReview == null) {
      return [];
    }

    const absoluteStrengthList = [
      review.actualReview.load1,
      review.actualReview.load2,
      review.actualReview.load3,
      review.actualReview.load4,
      review.actualReview.load5,
      review.actualReview.load6,
    ].filter((item) => item !== null);
    const vmp = [
      review.actualReview.vmp1,
      review.actualReview.vmp2,
      review.actualReview.vmp3,
      review.actualReview.vmp4,
      review.actualReview.vmp5,
      review.actualReview.vmp6,
    ].filter((item) => item !== null);

    if (absoluteStrengthList.length == 0 && props.user?.id != null) {
      notificationInfo("Aluno sem avaliação!");
    }

    const response = [];
    const absoluteStrength = review?.actualReview?.absoluteStrength;
    absoluteStrengthList.forEach((item, index) => {
      const rm = ((item * 100) / absoluteStrength).toFixed(2);
      response.push({
        absoluteStrength: item,
        vmp: vmp[index],
        rm: rm,
      });
    });
    return response;
  }
  function mountPrediction() {
    if (
      review?.actualReview == null ||
      review?.actualReview.slope == null ||
      review?.actualReview?.intercept == null
    ) {
      return [];
    }
    const absoluteStrength = review?.actualReview?.absoluteStrength;
    const slope = review?.actualReview?.slope;
    const intercept = review?.actualReview?.intercept;

    const response = [];
    var valueInitial = 30;
    for (let index = 0; index < 15; index++) {
      const abValue = ((valueInitial * absoluteStrength) / 100).toFixed(0);
      const vmp = (slope * valueInitial + intercept).toFixed(2);
      response.push({
        absoluteStrength: abValue,
        vmp: vmp,
        rm: valueInitial,
      });
      valueInitial += 5;
    }
    return response;
  }
  function chartVMP() {
    const lastReview = getlastReview();
    const vmp = [];

    vmp.push({
      load: Math.ceil(lastReview[0]?.rm - 5)+"%",

      vmpActual: null,
      vmpComparation: null,
    });
    var lastValue = Math.ceil(lastReview[0]?.rm - 5);
    lastReview.forEach((item) => {
      vmp.push({
        load: Math.ceil(item.rm)+"%",
        vmpActual: item.vmp,
        vmpComparation: null,
      });
      lastValue = Math.ceil(item.rm);
    });
    vmp.push({
      load: Math.ceil(lastValue + 5)+"%",
      vmpActual: null,
      vmpComparation: null,
    });
    return vmp;
  }
  return (
    <>
      <Divider />
      <Card
        id="card-table"
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <BicepsFlexed
                size={18}
                style={{ marginBottom: "-4px", marginRight: "10px" }}
              />
              <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                Última Avaliação
              </Text>
            </div>
          </div>
        }
        bordered={false}
      >
        <Table
          columns={ColumnsLastReview()}
          dataSource={getlastReview()}
          pagination={false}
          size="small"
          locale={{
            emptyText: "Sem registro",
          }}
        />
      </Card>
      <Row gutter={16} style={{ marginTop: "15px" }}>
        <Col span={24} style={{ textAlign: "left"  }}>
          <Text
            style={{
              fontSize: "15px",
              fontFamily: FONT,
            }}
          >
            Força Absoluta:
          </Text>
          <Text
            style={{
              fontSize: "15px",
              fontFamily: FONT,
              fontWeight: "bold",
            }}
          >
            {review?.actualReview?.absoluteStrength &&
              " " + review?.actualReview?.absoluteStrength + "  KG"}
          </Text>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16} style={{ marginTop: "15px" }}>
        <Col span={outerWidth < 501 ? 24 : 12} style={{ marginTop: "10px" }}>
          <Card
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  <Gauge
                    size={18}
                    style={{ marginBottom: "-4px", marginRight: "10px" }}
                  />
                  <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                    VMP = [{review?.actualReview?.slope}x +{" "}
                    {review?.actualReview?.intercept}]
                  </Text>
                </div>
              </div>
            }
            style={{ width: "100%" }}
          >
            {chartLiner(
              chartVMP(),
              "load",
              "vmpActual",
              "vmpComparation",
              "vmp",
              "vmp Comparação",
              10
            )}
          </Card>
        </Col>
        <Col span={outerWidth < 501 ? 24 : 12} style={{ marginTop: "10px" }}>
          <Card
            id="card-table"
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  <TrendingUpDown
                    size={18}
                    style={{ marginBottom: "-4px", marginRight: "10px" }}
                  />
                  <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                    Valores de Predição
                  </Text>
                </div>
              </div>
            }
            bordered={false}
          >
            <Table
              columns={ColumnsLastReview()}
              dataSource={mountPrediction()}
              pagination={false}
              size="small"
              locale={{
                emptyText: "Sem registro",
              }}
              scroll={{
                y: 413,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default FormNewUserPlanningBackSquad;
