import React, { useEffect, useState } from "react";

import {
  Row,
  Typography,
  Form,
  Col,
  Spin,
  Space,
  Button,
  Select,
  Divider,
  InputNumber,
  Input,
} from "antd";

import { FONT } from "constants/index";
import { notificationErroInternal, notificationInfo } from "util/api-utils";
import { getReviewUserById } from "services/review/review-service";
import FormNewUserPlanningBackSquad from "./planning-drawer-new-planning-back-squad";
import { getPlanningUserById } from "services/planning/planning-service";
import PlanningDetails from "./planning-details";
import { v4 as uuidv4 } from "uuid";
import FormNewUserPlanningNewExercise from "./planning-drawer-new-planning-new-exercise";
import FormNewUserPlanningHangClean from "./planning-drawer-new-planning-hang-clean";
import FormNewUserPlanningBarbellSquad from "./planning-drawer-new-planning-barbell-squad";

const { Text } = Typography;

const FormNewUserPlanningExercise = (props) => {
  const [loadingData, setLoadingData] = useState(false);
  const [form] = Form.useForm();
  const [review, setReview] = useState(null);
  const [type, setType] = useState("");
  const [showBackSquad, setShowBackSquad] = useState(false);
  const [planning, setPlanning] = useState([]);
  const [planningHistoric, setPlanningHistoric] = useState([]);
  const [showCvNew, setShowCvNew] = useState(false);
  const [showHangClean, setShowHangClean] = useState(false);
  const [showBarbell, setShowBarbell] = useState(false);
  const [canSave, setCanSave] = useState(true);

  useEffect(() => {
    setLoadingData(true);
    const type = props.exercise?.type ?? "";
    form.resetFields();
    const canSave = props.onSave ? true : false;
    setCanSave(canSave);

    if (props.exercise) {
      for (let index = 1; index < 7; index++) {
        const value = props.exercise[`load${index}`];
        const valueRm = props.exercise[`rm${index}`];
        const valueVmp = props.exercise[`vmp${index}`];

        if (value !== undefined) {
          form.setFieldsValue({
            [`load${index}`]: value,
            [`rm${index}`]: valueRm,
            [`vmp${index}`]: valueVmp,
          });
        }
      }
    }
    const additionalName = props.exercise?.additionalName;

    setType(type);
    form.setFieldsValue({
      type: type,
      additionalName: additionalName,
    });
    const showBackSquad =
      props.exercise &&
      props.exercise.type &&
      props.exercise.type == "BACK_SQUAD";

    const showCvNew =
      props.exercise &&
      props.exercise.type &&
      props.exercise.type == "NEW_EXERCISE";

    const showHangClean =
      props.exercise &&
      props.exercise.type &&
      props.exercise.type == "HANG_CLEAN";

    const showBarbell =
      props.exercise &&
      props.exercise.type &&
      props.exercise.type == "BARBELL_JUMP";

    setShowBackSquad(showBackSquad);
    setShowCvNew(showCvNew);
    setShowHangClean(showHangClean);
    setShowBarbell(showBarbell);
    getPlannings();
    getDataReview();
  }, [props]);

  const getDataReview = () => {
    if (props.user?.id) {
      getReviewUserById(props.user?.id)
        .then((res) => {
          setReview(res);
          setLoadingData(false);
          if (res?.actualReview == null) {
            notificationInfo("Aluno sem avaliação!");
          }
        })
        .catch((err) => {
          setLoadingData(false);
          notificationErroInternal(
            "Aconteceu um erro inesperado, por favor, tente mais tarde."
          );
        });
    } else {
      setReview(null);
      setLoadingData(false);
    }
  };

  const handleNewPlanning = () => {
    if (!showBackSquad && type == "BACK_SQUAD") {
      notificationInfo("Aluno sem avaliação!");
      return;
    }
    if (!showCvNew && type == "NEW_EXERCISE") {
      notificationInfo("Aluno sem avaliação!");
      return;
    }
    if (!showBarbell && type == "BARBELL_JUMP") {
      notificationInfo("Aluno sem avaliação!");
      return;
    }
    setLoadingData(true);

    form
      .validateFields()
      .then((values) => {
        if (props.exercise) {
          values.id = props.exercise.id;
          values.isNew = false;
        } else {
          values.id = uuidv4();
          values.isNew = true;
        }
        values.userId = props.user.id;
        values.reviewId = review.actualReview.id;
        const historic = planningHistoric.filter((item) => {
          return item.exercises.some(
            (exercise) => exercise.type === values.type
          );
        });
        values.cycle = historic.length + 1;
        props.onSave(values);
        onCloseDrawer();
        setLoadingData(false);
      })
      .catch((errors) => {
        setLoadingData(false);
      });
  };

  const onCloseDrawer = () => {
    props.onClose();
    setReview(null);
    setShowBackSquad(false);
    setShowCvNew(false);
  };

  function selectType(type) {
    setType(type);
    if (type == "BACK_SQUAD") {
      const absoluteStrengthList = [
        review.actualReview.load1,
        review.actualReview.load2,
        review.actualReview.load3,
        review.actualReview.load4,
        review.actualReview.load5,
        review.actualReview.load6,
      ].filter((item) => item !== null);
      if (absoluteStrengthList.length == 0 && props.user?.id != null) {
        notificationInfo("Aluno sem avaliação!");
        setShowBackSquad(false);
      } else {
        setShowBackSquad(true);
      }
    } else {
      setShowBackSquad(false);
    }
    if (type == "NEW_EXERCISE") {
      const absoluteStrengthList = [
        review.actualReview.load1CvNew,
        review.actualReview.load2CvNew,
        review.actualReview.load3CvNew,
        review.actualReview.load4CvNew,
        review.actualReview.load5CvNew,
        review.actualReview.load6CvNew,
      ].filter((item) => item !== null);
      if (absoluteStrengthList.length == 0 && props.user?.id != null) {
        notificationInfo("Aluno sem avaliação!");
        setShowCvNew(false);
      } else {
        setShowCvNew(true);
      }
    } else {
      setShowCvNew(false);
    }

    if (type == "BARBELL_JUMP") {
      const absoluteStrengthList = [
        review.actualReview.load1Ca,
        review.actualReview.load2Ca,
        review.actualReview.load3Ca,
        review.actualReview.load4Ca,
        review.actualReview.load5Ca,
        review.actualReview.load6Ca,
      ].filter((item) => item !== null);
      if (absoluteStrengthList.length == 0 && props.user?.id != null) {
        notificationInfo("Aluno sem avaliação!");
        setShowBarbell(false);
      } else {
        setShowBarbell(true);
      }
    } else {
      setShowBarbell(false);
    }
    const showHangClean = type == "HANG_CLEAN";
    setShowHangClean(showHangClean);

    for (let index = 1; index < 7; index++) {
      form.setFieldsValue({
        [`load${index}`]: undefined,
        [`rm${index}`]: undefined,
        [`vmp${index}`]: undefined,
      });
    }
    mountPlanning();
  }

  function setLoad(value, loadIndex) {
    if (showBackSquad) {
      const slope = review?.actualReview?.slope;
      const intercept = review?.actualReview?.intercept;
      const absoluteStrength = review?.actualReview?.absoluteStrength;
      const rm = ((value * 100) / absoluteStrength).toFixed(2);
      const vmp = (slope * rm + intercept).toFixed(2);
      form.setFieldsValue({
        [`load${loadIndex}`]: value,
        [`rm${loadIndex}`]: value == null ? value : rm,
        [`vmp${loadIndex}`]: value == null ? value : vmp,
      });
    }
    if (showCvNew) {
      const slope = review?.actualReview?.slopeCvNew;
      const intercept = review?.actualReview?.interceptCvNew;
      const absoluteStrength = review?.actualReview?.absoluteStrengthCvNew;
      const rm = ((value * 100) / absoluteStrength).toFixed(2);
      const vmp = (slope * rm + intercept).toFixed(2);
      form.setFieldsValue({
        [`load${loadIndex}`]: value,
        [`rm${loadIndex}`]: value == null ? value : rm,
        [`vmp${loadIndex}`]: value == null ? value : vmp,
      });
    }

    if (showBarbell) {
      const slope = review?.actualReview?.slopeCa;
      const intercept = review?.actualReview?.interceptCa;

      const rm = ((value - intercept) / slope).toFixed(2);

      form.setFieldsValue({
        [`load${loadIndex}`]: value,
        [`rm${loadIndex}`]: value == null ? value : rm,
      });
    }
    if (showHangClean) {
      const absoluteStrength = review?.actualReview?.absoluteStrengthClean;
      var rm = ((value * 100) / absoluteStrength).toFixed(2);
      if (absoluteStrength == 0) {
        rm = 0;
      }
      form.setFieldsValue({
        [`load${loadIndex}`]: value,
        [`rm${loadIndex}`]: value == null ? value : rm,
      });
    }

    mountPlanning();
  }
  function calMinMaxAverage(field) {
    const list = [];
    for (let index = 1; index < 7; index++) {
      const item = form.getFieldValue(`${field}${index}`);
      if (item != null && item != undefined) {
        list.push(parseFloat(item));
      }
    }
    if (list.length == 0) {
      list.push(0);
    }
    const min = Math.min(...list);
    const max = Math.max(...list);
    const amount = list.reduce(
      (acumulador, valorAtual) => acumulador + valorAtual,
      0
    );
    const average = amount / list.length;
    return {
      min: min,
      max: max,
      average: average.toFixed(2),
      size: list.length,
    };
  }

  function getNewPlanning() {
    const date = form.getFieldValue("date")?.format(dateFormat);
    const type = form.getFieldValue("type");

    const load = calMinMaxAverage("load");
    const rm = calMinMaxAverage("rm");
    const vmp = calMinMaxAverage("vmp");
    const exercises = [
      {
        type: type,
        loadInitial: load.min,
        loadFinal: load.max,
        loadAverage: load.average,
        week: load.size,
        vmpInitial: vmp.min,
        vmpFinal: vmp.max,
        vmpAverage: vmp.average,
        rmInitial: rm.min,
        rmFinal: rm.max,
        rmAverage: rm.average,
      },
    ];
    return {
      data: date,
      exercises: exercises,
    };
  }
  function mountPlanning() {
    const newPlanning = [];
    newPlanning.push(getNewPlanning());
    const type = form.getFieldValue("type");

    if (planningHistoric.length > 0 && type != "ADDITIONAL_EXERCISE") {
      newPlanning.push(planningHistoric[0]);
    }
    setPlanning(newPlanning);
  }
  function getPlannings() {
    if (props.user?.id) {
      getPlanningUserById(props.user?.id)
        .then((res) => {
          setPlanningHistoric(res);
          const newPlanning = [];
          newPlanning.push(getNewPlanning());
          const type = form.getFieldValue("type");

          if (res.length > 0 && type != "ADDITIONAL_EXERCISE") {
            newPlanning.push(res[0]);
          }
          setPlanning(newPlanning);
        })
        .catch((err) => {
          notificationErroInternal(
            "Aconteceu um erro inesperado, por favor, tente mais tarde."
          );
        });
    }
  }
  function getMountCycleDefault() {
    return (
      <>
        <Row gutter={16}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Text
              style={{
                fontSize: "15px",
                fontFamily: FONT,
              }}
            >
              Montagem do Ciclo
            </Text>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={8}>
            <Form.Item
              name="load1"
              label={<span style={{ fontFamily: FONT }}>Load 1</span>}
            >
              <InputNumber
                onChange={(e) => setLoad(e, 1)}
                decimalSeparator=","
                disabled={!canSave}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="vmp1"
              label={<span style={{ fontFamily: FONT }}>VMP 1</span>}
            >
              <InputNumber
                decimalSeparator=","
                disabled={true}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="rm1"
              label={<span style={{ fontFamily: FONT }}>%1RM 1</span>}
            >
              <InputNumber
                decimalSeparator=","
                disabled={true}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="load2"
              label={<span style={{ fontFamily: FONT }}>Load 2</span>}
            >
              <InputNumber
                onChange={(e) => setLoad(e, 2)}
                decimalSeparator=","
                disabled={!canSave}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="vmp2"
              label={<span style={{ fontFamily: FONT }}>VMP 2</span>}
            >
              <InputNumber
                decimalSeparator=","
                disabled={true}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="rm2"
              label={<span style={{ fontFamily: FONT }}>%1RM 2</span>}
            >
              <InputNumber
                decimalSeparator=","
                disabled={true}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="load3"
              label={<span style={{ fontFamily: FONT }}>Load 3</span>}
            >
              <InputNumber
                onChange={(e) => setLoad(e, 3)}
                decimalSeparator=","
                disabled={!canSave}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="vmp3"
              label={<span style={{ fontFamily: FONT }}>VMP 3</span>}
            >
              <InputNumber
                decimalSeparator=","
                disabled={true}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="rm3"
              label={<span style={{ fontFamily: FONT }}>%1RM 3</span>}
            >
              <InputNumber
                decimalSeparator=","
                disabled={true}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "5px" }}>
          <Col span={8}>
            <Form.Item
              name="load4"
              label={<span style={{ fontFamily: FONT }}>Load 4</span>}
            >
              <InputNumber
                onChange={(e) => setLoad(e, 4)}
                decimalSeparator=","
                disabled={!canSave}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="vmp4"
              label={<span style={{ fontFamily: FONT }}>VMP 4</span>}
            >
              <InputNumber
                disabled={true}
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="rm4"
              label={<span style={{ fontFamily: FONT }}>%1RM 4</span>}
            >
              <InputNumber
                decimalSeparator=","
                disabled={true}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="load5"
              label={<span style={{ fontFamily: FONT }}>Load 5</span>}
            >
              <InputNumber
                onChange={(e) => setLoad(e, 5)}
                decimalSeparator=","
                disabled={!canSave}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="vmp5"
              label={<span style={{ fontFamily: FONT }}>VMP 5</span>}
            >
              <InputNumber
                disabled={true}
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="rm5"
              label={<span style={{ fontFamily: FONT }}>%1RM 5</span>}
            >
              <InputNumber
                decimalSeparator=","
                disabled={true}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="load6"
              label={<span style={{ fontFamily: FONT }}>Load 6</span>}
            >
              <InputNumber
                decimalSeparator=","
                onChange={(e) => setLoad(e, 6)}
                disabled={!canSave}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="vmp6"
              label={<span style={{ fontFamily: FONT }}>VMP 6</span>}
            >
              <InputNumber
                disabled={true}
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="rm6"
              label={<span style={{ fontFamily: FONT }}>%1RM 6</span>}
            >
              <InputNumber
                decimalSeparator=","
                disabled={true}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  function getMountCycleHangCleanOrBarbell() {
    const name = type == "BARBELL_JUMP" ? "ALT(CM)" : "%1RM";
    return (
      <>
        <Row gutter={16}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Text
              style={{
                fontSize: "15px",
                fontFamily: FONT,
              }}
            >
              Montagem do Ciclo
            </Text>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={12}>
            <Form.Item
              name="load1"
              label={<span style={{ fontFamily: FONT }}>Load 1</span>}
            >
              <InputNumber
                onChange={(e) => setLoad(e, 1)}
                decimalSeparator=","
                disabled={!canSave}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="rm1"
              label={<span style={{ fontFamily: FONT }}>{name} 1</span>}
            >
              <InputNumber
                decimalSeparator=","
                disabled={true}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="load2"
              label={<span style={{ fontFamily: FONT }}>Load 2</span>}
            >
              <InputNumber
                onChange={(e) => setLoad(e, 2)}
                decimalSeparator=","
                disabled={!canSave}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="rm2"
              label={<span style={{ fontFamily: FONT }}>{name} 2</span>}
            >
              <InputNumber
                decimalSeparator=","
                disabled={true}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="load3"
              label={<span style={{ fontFamily: FONT }}>Load 3</span>}
            >
              <InputNumber
                onChange={(e) => setLoad(e, 3)}
                decimalSeparator=","
                disabled={!canSave}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="rm3"
              label={<span style={{ fontFamily: FONT }}>{name} 3</span>}
            >
              <InputNumber
                decimalSeparator=","
                disabled={true}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "5px" }}>
          <Col span={12}>
            <Form.Item
              name="load4"
              label={<span style={{ fontFamily: FONT }}>Load 4</span>}
            >
              <InputNumber
                onChange={(e) => setLoad(e, 4)}
                decimalSeparator=","
                disabled={!canSave}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="rm4"
              label={<span style={{ fontFamily: FONT }}>{name} 4</span>}
            >
              <InputNumber
                decimalSeparator=","
                disabled={true}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="load5"
              label={<span style={{ fontFamily: FONT }}>Load 5</span>}
            >
              <InputNumber
                onChange={(e) => setLoad(e, 5)}
                decimalSeparator=","
                disabled={!canSave}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="rm5"
              label={<span style={{ fontFamily: FONT }}>{name} 5</span>}
            >
              <InputNumber
                decimalSeparator=","
                disabled={true}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="load6"
              label={<span style={{ fontFamily: FONT }}>Load 6</span>}
            >
              <InputNumber
                decimalSeparator=","
                onChange={(e) => setLoad(e, 6)}
                disabled={!canSave}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="rm6"
              label={<span style={{ fontFamily: FONT }}>{name} 6</span>}
            >
              <InputNumber
                decimalSeparator=","
                disabled={true}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
  function getMountCycleAdditionlExercise() {
    const name = type == "BARBELL_JUMP" ? "ALT(CM)" : "%1RM";
    return (
      <>
        <Row gutter={16}>
          <Col span={24} style={{ textAlign: "center" }}>
            <Text
              style={{
                fontSize: "15px",
                fontFamily: FONT,
              }}
            >
              Montagem do Ciclo
            </Text>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: "15px" }}>
          <Col span={4}>
            <Form.Item
              name="load1"
              label={<span style={{ fontFamily: FONT }}>Load 1</span>}
            >
              <InputNumber
                onChange={(e) => setLoad(e, 1)}
                disabled={!canSave}
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              name="load2"
              label={<span style={{ fontFamily: FONT }}>Load 2</span>}
            >
              <InputNumber
                onChange={(e) => setLoad(e, 2)}
                disabled={!canSave}
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              name="load3"
              label={<span style={{ fontFamily: FONT }}>Load 3</span>}
            >
              <InputNumber
                onChange={(e) => setLoad(e, 3)}
                disabled={!canSave}
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              name="load4"
              label={<span style={{ fontFamily: FONT }}>Load 4</span>}
            >
              <InputNumber
                onChange={(e) => setLoad(e, 4)}
                disabled={!canSave}
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              name="load5"
              label={<span style={{ fontFamily: FONT }}>Load 5</span>}
            >
              <InputNumber
                onChange={(e) => setLoad(e, 5)}
                disabled={!canSave}
                decimalSeparator=","
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              name="load6"
              label={<span style={{ fontFamily: FONT }}>Load 6</span>}
            >
              <InputNumber
                decimalSeparator=","
                onChange={(e) => setLoad(e, 6)}
                disabled={!canSave}
                style={{ fontFamily: FONT, width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Spin spinning={loadingData}>
        <Form form={form} layout="vertical">
          {canSave && (
            <Row justify="end" style={{ marginBottom: "25px" }}>
              <Space>
                <Button style={{ fontFamily: FONT }} onClick={onCloseDrawer}>
                  Cancelar
                </Button>
                <Button
                  style={{ fontFamily: FONT }}
                  onClick={handleNewPlanning}
                  type="primary"
                >
                  Salvar
                </Button>
              </Space>
            </Row>
          )}

          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="type"
                label={<span style={{ fontFamily: FONT }}>Exercício</span>}
                rules={[
                  {
                    required: true,
                    message: "É necessário informar o exercício",
                  },
                ]}
              >
                <Select
                  disabled={!canSave}
                  onChange={(event) => {
                    selectType(event);
                  }}
                >
                  <Option value="BACK_SQUAD">Agachamento</Option>
                  <Option value="BARBELL_JUMP">Salto com Barra</Option>
                  <Option value="HANG_CLEAN">Levantamento Olímpico</Option>
                  <Option value="NEW_EXERCISE">Exercício Específico</Option>
                  <Option value="ADDITIONAL_EXERCISE">
                    Exercício Complementar
                  </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {type == "ADDITIONAL_EXERCISE" && (
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="additionalName"
                  rules={[
                    {
                      required: true,
                      message: "É necessário informar o nome do exercício",
                    },
                  ]}
                  label={<span style={{ fontFamily: FONT }}>Nome</span>}
                >
                  <Input disabled={!canSave} style={{ fontFamily: FONT }} />
                </Form.Item>
              </Col>
            </Row>
          )}
          {showBackSquad && <FormNewUserPlanningBackSquad review={review} />}
          {showCvNew && <FormNewUserPlanningNewExercise review={review} />}
          {showHangClean && <FormNewUserPlanningHangClean review={review} />}
          {showBarbell && <FormNewUserPlanningBarbellSquad review={review} />}

          {type != "" && (
            <>
              <Divider />

              {type == "HANG_CLEAN" || type == "BARBELL_JUMP"
                ? getMountCycleHangCleanOrBarbell()
                : type == "ADDITIONAL_EXERCISE"
                ? getMountCycleAdditionlExercise()
                : getMountCycleDefault()}
              <Divider />

              {planning.length > 0 && (
                <PlanningDetails
                  data={planning}
                  type={type}
                  canDelete={false}
                />
              )}
            </>
          )}
        </Form>
      </Spin>
    </>
  );
};
export default FormNewUserPlanningExercise;
