import React, { useEffect, useState } from "react";

import { Row, Typography, Col, Divider, Card, Table } from "antd";
import { BicepsFlexed, Gauge, TrendingUpDown } from "lucide-react";

import { FONT } from "constants/index";

import { notificationInfo } from "util/api-utils";
import { ColumnsLastReview } from "./columns-table";
import { chartLiner } from "../reviews/reviews-drawer-charts";

const { Text } = Typography;

const FormNewUserPlanningBarbellSquad = (props) => {
  const [review, setReview] = useState(null);

  useEffect(() => {
    setReview(props.review);
  }, [props]);

  function getlastReview() {
    if (review?.actualReview == null) {
      return [];
    }

    const absoluteStrengthList = [
      review.actualReview.load1Ca,
      review.actualReview.load2Ca,
      review.actualReview.load3Ca,
      review.actualReview.load4Ca,
      review.actualReview.load5Ca,
      review.actualReview.load6Ca,
    ].filter((item) => item !== null);
    const vmp = [
      review.actualReview.vmp1Ca,
      review.actualReview.vmp2Ca,
      review.actualReview.vmp3Ca,
      review.actualReview.vmp4Ca,
      review.actualReview.vmp5Ca,
      review.actualReview.vmp6Ca,
    ].filter((item) => item !== null);

    const power = [
      review.actualReview.power1Ca,
      review.actualReview.power2Ca,
      review.actualReview.power3Ca,
      review.actualReview.power4Ca,
      review.actualReview.power5Ca,
      review.actualReview.power6Ca,
    ].filter((item) => item !== null);

    if (absoluteStrengthList.length == 0 && props.user?.id != null) {
      notificationInfo("Aluno sem avaliação!");
    }

    const response = [];
    const absoluteStrength = review?.actualReview?.absoluteStrength;
    absoluteStrengthList.forEach((item, index) => {
      const rm = ((item * 100) / absoluteStrength).toFixed(2);
      response.push({
        absoluteStrength: item,
        power: power[index],
        rm: vmp[index],
      });
    });
    return response;
  }
  function mountPrediction() {
    if (
      review?.actualReview == null ||
      review?.actualReview.slope == null ||
      review?.actualReview?.intercept == null
    ) {
      return [];
    }
    const slope = review?.actualReview?.slopeCa;
    const intercept = review?.actualReview?.interceptCa;

    const response = [];
    var valueInitial = 15;
    for (let index = 0; index < 7; index++) {
      const rm = ((valueInitial - intercept) / slope).toFixed(2);
      response.push({
        absoluteStrength: valueInitial,
        rm: rm,
      });
      valueInitial += 5;
    }
    return response;
  }
  function chartVMP() {
    const lastReview = getlastReview();
    const vmp = [];

    vmp.push({
      load: lastReview[0]?.absoluteStrength - 5,

      vmpActual: null,
      vmpComparation: null,
    });
    var lastValue = lastReview[0]?.absoluteStrength - 5;
    lastReview.forEach((item) => {
      vmp.push({
        load: item.absoluteStrength,
        vmpActual: item.power,
        vmpComparation: null,
      });
      lastValue = Math.ceil(item.absoluteStrength);
    });
    vmp.push({
      load: lastValue + 5,
      vmpActual: null,
      vmpComparation: null,
    });
    return vmp;
  }
  return (
    <>
      <Divider />
      <Card
        id="card-table"
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <BicepsFlexed
                size={18}
                style={{ marginBottom: "-4px", marginRight: "10px" }}
              />
              <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                Última Avaliação
              </Text>
            </div>
          </div>
        }
        bordered={false}
      >
        <Table
          columns={ColumnsLastReview("BARBELL_JUMP")}
          dataSource={getlastReview()}
          pagination={false}
          size="small"
          locale={{
            emptyText: "Sem registro",
          }}
        />
      </Card>

      <Divider />
      <Row gutter={16} style={{ marginTop: "15px" }}>
        <Col span={outerWidth < 501 ? 24 : 12} style={{ marginTop: "10px" }}>
          <Card
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  <Gauge
                    size={18}
                    style={{ marginBottom: "-4px", marginRight: "10px" }}
                  />
                  <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                    ALT(CM) e Potência
                  </Text>
                </div>
              </div>
            }
            style={{ width: "100%" }}
          >
            {chartLiner(
              chartVMP(),
              "load",
              "vmpActual",
              "vmpComparation",
              "Potência",
              "vmp Comparação",
              10
            )}
          </Card>
        </Col>
        <Col span={outerWidth < 501 ? 24 : 12} style={{ marginTop: "10px" }}>
          <Card
            id="card-table"
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  <TrendingUpDown
                    size={18}
                    style={{ marginBottom: "-4px", marginRight: "10px" }}
                  />
                  <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                    Valores de Predição
                  </Text>
                </div>
              </div>
            }
            bordered={false}
            style={{ minHeight: "505px" }}
          >
            <Table
              columns={ColumnsLastReview("BARBELL_JUMP")}
              dataSource={mountPrediction()}
              pagination={false}
              size="small"
              locale={{
                emptyText: "Sem registro",
              }}
              scroll={{
                y: 413,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default FormNewUserPlanningBarbellSquad;
