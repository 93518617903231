import React, { useEffect, useState } from "react";

import { Row, Typography, Col, Divider, Card, Table } from "antd";
import { TrendingUpDown } from "lucide-react";

import { FONT } from "constants/index";

import { ColumnsLastReview } from "./columns-table";

const { Text } = Typography;

const FormNewUserPlanningHangClean = (props) => {
  const [review, setReview] = useState(null);

  useEffect(() => {
    setReview(props.review);
  }, [props]);

  function mountPrediction() {
    if (
      review?.actualReview == null ||
      review?.actualReview.slopeClean == null ||
      review?.actualReview?.interceptClean == null
    ) {
      return [];
    }
    const absoluteStrength = review?.actualReview?.absoluteStrengthClean;
    const slope = review?.actualReview?.slopeClean;
    const intercept = review?.actualReview?.interceptClean;

    const response = [];
    var valueInitial = 55;
    for (let index = 0; index < 9; index++) {
      const abValue = ((valueInitial * absoluteStrength) / 100).toFixed(0);
      const vmp = (slope * valueInitial + intercept).toFixed(2);
      response.push({
        absoluteStrength: abValue,
        vmp: vmp,
        rm: valueInitial,
      });
      valueInitial += 5;
    }
    return response;
  }

  return (
    <>
      <Divider />

      <Row gutter={16} style={{ marginTop: "15px" }}>
        <Col span={24} style={{ textAlign: "left" }}>
          <Text
            style={{
              fontSize: "15px",
              fontFamily: FONT,
            }}
          >
            Força Absoluta:
          </Text>
          <Text
            style={{
              fontSize: "15px",
              fontFamily: FONT,
              fontWeight: "bold",
            }}
          >
            {review?.actualReview?.absoluteStrengthClean &&
              " " + review?.actualReview?.absoluteStrengthClean + "  KG"}
          </Text>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginTop: "15px" }}>
        <Col span={outerWidth < 501 ? 24 : 24} style={{ marginTop: "10px" }}>
          <Card
            id="card-table"
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  <TrendingUpDown
                    size={18}
                    style={{ marginBottom: "-4px", marginRight: "10px" }}
                  />
                  <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                    Valores de Predição
                  </Text>
                </div>
              </div>
            }
            bordered={false}
          >
            <Table
              columns={ColumnsLastReview("HANG_CLEAN")}
              dataSource={mountPrediction()}
              pagination={false}
              size="small"
              locale={{
                emptyText: "Sem registro",
              }}
              scroll={{
                y: 413,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default FormNewUserPlanningHangClean;
