import React, { useEffect, useState } from "react";
import { Spin, Button, Row, Input, Space, Card, Table, Typography } from "antd";
import { ArrowRight, Dumbbell, Search } from "lucide-react";
import { FONT } from "constants/index";
import { notificationErroInternal, notificationSucess } from "util/api-utils";
import { ColumnsUser } from "./columns-table";
import DrawerApp from "components/drawer-app";
import {
  getUsersReviews,
  getReviewUserById,
  putReview,
  getReviewUserOpenById,
} from "services/review/review-service";
import { formNewUserCicle } from "./load-control-drawer-new-cicle";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import FormUserComparation from "./load-control-drawer-comparation";
import { InDevelopment } from "views/util/emptyState";

dayjs.extend(customParseFormat);
const dateFormat = "DD/MM/YYYY";

const { Text } = Typography;

const LoadControlClient = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(null);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openComparation, setOpenComparation] = useState(false);
  const formRef = React.useRef(null);
  const [editId, setEditId] = useState(null);
  const [imgAvatar, setImgAvatar] = useState("");
  const [reviewCa, setReviewCa] = useState(false);
  const [reviewCvNew, setReviewCvNew] = useState(false);
  const [dataDrawer, setDataDrawer] = useState({});

  useEffect(() => {
    refreshData();
  }, []);

  function refreshData() {
    setLoading(true);
    getData();
  }

  function getData() {
    let obj = {
      search: search,
    };

    if (search == null || search == "") {
      obj = {};
    }
    getUsersReviews(obj)
      .then((res) => {
        res = res.map((obj) => ({
          ...obj,
          comparation: comparation,
          edit: edit,
          profile:
            obj.role == "ADMIN"
              ? "Administrador"
              : obj.role == "USER_ADMIN"
              ? "Professor"
              : "Aluno",
        }));
        setLoading(false);
        setData(res);
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  const showDrawer = () => {
    setOpen(true);
  };

  const showDrawerComparation = () => {
    setOpenComparation(true);
  };

  const onClose = () => {
    formRef.current?.resetFields();
    setEditId(null);
    setOpen(false);
    refreshData();
    setReviewCa(false);
    setReviewCvNew(false);
  };

  const onCloseComparation = () => {
    setEditId(null);
    setDataDrawer({ reviews: [] });
    setOpenComparation(false);
    refreshData();
  };

  function edit(row) {
    setEditId(row.id);
    setImgAvatar(row.img);

    setTimeout(() => {
      getReviewUserOpenById(row.id)
        .then((res) => {
          formRef.current?.setFieldsValue({
            name: row.name,
            codSprint: res.codSprint,
            linearSprint: res.linearSprint,
          });
        })
        .catch((err) => {
          setLoading(false);
          notificationErroInternal(
            "Aconteceu um erro inesperado, por favor, tente mais tarde."
          );
        }, 300);
    });

    showDrawer();
  }
  function comparation(row) {
    setEditId(row.id);
    setImgAvatar(row.img);
    setLoading(true);
    getReviewUserById(row.id)
      .then((res) => {
        setDataDrawer(res);
        setLoading(false);
        showDrawerComparation();
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  const handleNewReview = () => {
    setLoading(true);

    formRef?.current
      ?.validateFields()
      .then((values) => {
        values.userId = editId;
        values.djBox = !values.djBox ? 30 : values.djBox;
        values.date = values.date.format(dateFormat);
        putReview(values)
          .then(() => {
            setLoading(false);
            notificationSucess();
            onClose();
          })
          .catch(() => {
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
            setLoading(false);
          });
      })
      .catch((errors) => {
        setLoading(false);
        console.log(errors);
      });
  };

  return (
    <>
    <div>
             <InDevelopment />

    </div>

 {/*  <div className="container-reviews-client">
      <Spin size="large" spinning={loading}>
        <Row gutter={24} style={{ padding: "10px" }}>
          <Space>
            <Input
              placeholder="Pesquisar Aluno"
              onChange={(value) => setSearch(value.target.value)}
              suffix={<Search size={18} color="#e0e0e0" />}
              style={{ height: "40px" }}
              onPressEnter={refreshData}
            />

            <Button
              style={{ fontFamily: FONT }}
              type="primary"
              icon={
                <ArrowRight
                  size={18}
                  style={{ marginBottom: "-3px", marginRight: "7px" }}
                />
              }
              onClick={refreshData}
            >
              Filtrar
            </Button>
          </Space>
        </Row>
        <Row gutter={[24, 24]} style={{ marginLeft: "0px" }}>
          <Space
            direction="vertical"
            size={"small"}
            style={{
              flex: "1 1 500px",
              minWidth: outerWidth < 501 ? "0px" : "500px",
            }}
          >
            <Card
              id="card-table"
              title={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ flex: 1 }}>
                    <Dumbbell
                      size={18}
                      style={{ marginBottom: "-4px", marginRight: "10px" }}
                    />
                    <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                      Ciclo dos Alunos
                    </Text>
                  </div>
                </div>
              }
              bordered={false}
            >
              <Table
                columns={ColumnsUser()}
                dataSource={data}
                pagination={{ pageSize: 10, showSizeChanger: false }}
                locale={{
                  emptyText: "Sem registro",
                }}
              />
            </Card>
          </Space>
        </Row>
      </Spin>

      <DrawerApp
        open={open}
        onClose={onClose}
        title={"Novo Ciclo"}
        body={formNewUserCicle(
          formRef,
          imgAvatar,
          reviewCa,
          setReviewCa,
          true,
          reviewCvNew,
          setReviewCvNew
        )}
        size={"large"}
        actionSave={handleNewReview}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      <DrawerApp
        open={openComparation}
        onClose={onCloseComparation}
        title={"Acompanhamento"}
        body={
          <FormUserComparation dataDrawer={dataDrawer} imgAvatar={imgAvatar} />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "90%"}
      />
      </div>*/}
      </>
  );
};
export default LoadControlClient;
