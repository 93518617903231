import React, { useEffect, useState } from "react";

import { Typography, Divider, Card, Table, Button, Space, Row } from "antd";
import { BicepsFlexed } from "lucide-react";

import { FONT } from "constants/index";

import { ColumnsExercises } from "./columns-table";

const { Text } = Typography;

const PlanningExercises = (props) => {
  const [exercises, setExercises] = useState([]);

  useEffect(() => {
    setExercises(props.exercises);
  }, [props]);

  return (
    <>
      {props.newExercise && (
        <>
          <Divider />
          <Row justify="end" style={{ marginBottom: "25px" }}>
            <Space>
              <Button
                style={{ fontFamily: FONT }}
                onClick={() => props.newExercise()}
                type="primary"
              >
                Novo Exercício
              </Button>
            </Space>
          </Row>
        </>
      )}

      <Card
        id="card-table"
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <BicepsFlexed
                size={18}
                style={{ marginBottom: "-4px", marginRight: "10px" }}
              />
              <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                Exercícios
              </Text>
            </div>
          </div>
        }
        bordered={false}
      >
        <Table
          columns={ColumnsExercises()}
          dataSource={exercises}
          pagination={false}
          size="small"
          locale={{
            emptyText: "Sem registro",
          }}
        />
      </Card>
    </>
  );
};
export default PlanningExercises;
