export const ColumnsChart = () => {
  const table = [
    {
      title: "Semana",
      dataIndex: "load",
      width: "15%",
    },
    {
      title: "VMP 1",
      dataIndex: "vmp1",
      width: "15%",
    },
    {
      title: "VMP 2",
      dataIndex: "vmp2",
      width: "15%",
    },
    {
      title: "VMP 3",
      dataIndex: "vmp3",
      width: "15%",
    },
    {
      title: "VMP 4",
      dataIndex: "vmp4",
      width: "15%",
    },
  ];

  return table;
};
