import {  Result } from "antd";

export const CustomEmptyState = () => {
    return <Result status="404" subTitle="Desculpe, dados não encontrados." />;
  };

  export const InDevelopment = () => {
    return <Result status="403" subTitle="Funcionalidade em construção." />;
  };

  export const ListEmptyState = (props) => {
    return <Result status="404" subTitle={props.subTitle} />;
  };