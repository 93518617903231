import React, { useEffect, useState } from "react";

import { Row, Typography, Col, Divider, Card, Table } from "antd";
import { BicepsFlexed, Gauge, TrendingUpDown } from "lucide-react";

import { FONT } from "constants/index";

import { notificationInfo } from "util/api-utils";
import { ColumnsLastReview } from "./columns-table";
import { chartLiner } from "../reviews/reviews-drawer-charts";

const { Text } = Typography;

const FormNewUserPlanningNewExercise = (props) => {
  const [review, setReview] = useState(null);

  useEffect(() => {
    setReview(props.review);
  }, [props]);

  function getlastReview() {
    if (review?.actualReview == null) {
      return [];
    }

    const absoluteStrengthList = [
      review.actualReview.load1CvNew,
      review.actualReview.load2CvNew,
      review.actualReview.load3CvNew,
      review.actualReview.load4CvNew,
      review.actualReview.load5CvNew,
      review.actualReview.load6CvNew,
    ].filter((item) => item !== null);
    const vmp = [
      review.actualReview.vmp1CvNew,
      review.actualReview.vmp2CvNew,
      review.actualReview.vmp3CvNew,
      review.actualReview.vmp4CvNew,
      review.actualReview.vmp5CvNew,
      review.actualReview.vmp6CvNew,
    ].filter((item) => item !== null);

    if (absoluteStrengthList.length == 0 && props.user?.id != null) {
      notificationInfo("Aluno sem avaliação!");
    }

    const response = [];
    const absoluteStrength = review?.actualReview?.absoluteStrengthCvNew;
    absoluteStrengthList.forEach((item, index) => {
      const rm = ((item * 100) / absoluteStrength).toFixed(2);
      response.push({
        absoluteStrength: item,
        vmp: vmp[index],
        rm: rm,
      });
    });
    return response;
  }
  function mountPrediction() {
    if (
      review?.actualReview == null ||
      review?.actualReview.slopeCvNew == null ||
      review?.actualReview?.interceptCvNew == null
    ) {
      return [];
    }
    const absoluteStrength = review?.actualReview?.absoluteStrengthCvNew;
    const slope = review?.actualReview?.slopeCvNew;
    const intercept = review?.actualReview?.interceptCvNew;

    const response = [];
    var valueInitial = 30;
    for (let index = 0; index < 15; index++) {
      const abValue = ((valueInitial * absoluteStrength) / 100).toFixed(0);
      const vmp = (slope * valueInitial + intercept).toFixed(2);
      response.push({
        absoluteStrength: abValue,
        vmp: vmp,
        rm: valueInitial,
      });
      valueInitial += 5;
    }
    return response;
  }
  function chartVMP() {
    const lastReview = getlastReview();
    const vmp = [];

    vmp.push({
      load: Math.ceil(lastReview[0]?.rm - 5) + "%",

      vmpActual: null,
      vmpComparation: null,
    });
    var lastValue = Math.ceil(lastReview[0]?.rm - 5);
    lastReview.forEach((item) => {
      vmp.push({
        load: Math.ceil(item.rm) + "%",
        vmpActual: item.vmp,
        vmpComparation: null,
      });
      lastValue = Math.ceil(item.rm);
    });
    vmp.push({
      load: Math.ceil(lastValue + 5) + "%",
      vmpActual: null,
      vmpComparation: null,
    });
    return vmp;
  }
  return (
    <>
      <Divider />
      <Row gutter={16} style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Col span={24} style={{ textAlign: "left" }}>
          <Text
            style={{
              fontSize: "15px",
              fontFamily: FONT,
            }}
          >
            Exercício:
          </Text>
          <Text
            style={{
              fontSize: "15px",
              fontFamily: FONT,
              fontWeight: "bold",
            }}
          >
            {" " + review?.actualReview?.nameCvNew}
          </Text>
        </Col>
        <Col span={24} style={{ textAlign: "left" }}>
          <Text
            style={{
              fontSize: "15px",
              fontFamily: FONT,
            }}
          >
            1RM:
          </Text>
          <Text
            style={{
              fontSize: "15px",
              fontFamily: FONT,
              fontWeight: "bold",
            }}
          >
            {" " + review?.actualReview?.v1RmCvNew}
          </Text>
        </Col>
      </Row>
      <Card
        id="card-table"
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <BicepsFlexed
                size={18}
                style={{ marginBottom: "-4px", marginRight: "10px" }}
              />
              <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                Última Avaliação
              </Text>
            </div>
          </div>
        }
        bordered={false}
      >
        <Table
          columns={ColumnsLastReview()}
          dataSource={getlastReview()}
          pagination={false}
          size="small"
          locale={{
            emptyText: "Sem registro",
          }}
        />
      </Card>
      <Row gutter={16} style={{ marginTop: "15px" }}>
        <Col span={24} style={{ textAlign: "left" }}>
          <Text
            style={{
              fontSize: "15px",
              fontFamily: FONT,
            }}
          >
            Força Absoluta:
          </Text>
          <Text
            style={{
              fontSize: "15px",
              fontFamily: FONT,
              fontWeight: "bold",
            }}
          >
            {review?.actualReview?.absoluteStrengthCvNew &&
              " " + review?.actualReview?.absoluteStrengthCvNew + "  KG"}
          </Text>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16} style={{ marginTop: "15px" }}>
        <Col span={outerWidth < 501 ? 24 : 12} style={{ marginTop: "10px" }}>
          <Card
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  <Gauge
                    size={18}
                    style={{ marginBottom: "-4px", marginRight: "10px" }}
                  />
                  <Text style={{ fontSize: "14px", fontFamily: FONT }}>
                    VMP = [{review?.actualReview?.slopeCvNew}x +{" "}
                    {review?.actualReview?.interceptCvNew}]
                  </Text>
                </div>
              </div>
            }
            style={{ width: "100%" }}
          >
            {chartLiner(
              chartVMP(),
              "load",
              "vmpActual",
              "vmpComparation",
              "vmp",
              "vmp Comparação",
              10
            )}
          </Card>
        </Col>
        <Col span={outerWidth < 501 ? 24 : 12} style={{ marginTop: "10px" }}>
          <Card
            id="card-table"
            title={
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ flex: 1 }}>
                  <TrendingUpDown
                    size={18}
                    style={{ marginBottom: "-4px", marginRight: "10px" }}
                  />
                  <Text style={{ fontSize: "15px", fontFamily: FONT }}>
                    Valores de Predição
                  </Text>
                </div>
              </div>
            }
            bordered={false}
          >
            <Table
              columns={ColumnsLastReview()}
              dataSource={mountPrediction()}
              pagination={false}
              size="small"
              locale={{
                emptyText: "Sem registro",
              }}
              scroll={{
                y: 413,
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};
export default FormNewUserPlanningNewExercise;
