import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  InputNumber,
  Row,
  Space,
  Spin,
} from "antd";
import {
  ChartColumnStacked,
  Check,
  Edit,
  RotateCw,
  UserRound,
} from "lucide-react";
import {
  getUsersPlanningToTraining,
  saveTraining,
} from "services/planning/planning-service";
import {
  notificationErroInternal,
  notificationInfo,
  notificationSucessMsg,
} from "util/api-utils";
import { translationsTypes } from "../planning/columns-table";
import { FONT } from "constants/index";
import DrawerApp from "components/drawer-app";
import PlanningExercises from "../planning/planning-drawer-exercises";
import FormNewUserPlanningExercise from "../planning/planning-drawer-new-exercise";
import ManagerialChart from "./managerial-drawer-chart";

const { Meta } = Card;

const Managerial = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [openChart, setOpenChart] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(Date.now());
  const [userTrainer, setUserTrainer] = useState(null);
  const [openDetailItem, setOpenDetailItem] = useState(false);
  const [exercise, setExercise] = useState(null);

  useEffect(() => {
    refreshData();
  }, []);

  function refreshData() {
    setLastUpdated(Date.now());
    setLoading(true);
    getData();
  }
  const onCloseDetail = () => {
    setOpenDetail(false);
  };

  const onCloseChart = () => {
    setOpenChart(false);
  };

  function getData() {
    getUsersPlanningToTraining()
      .then((res) => {
        setLoading(false);
        setData(res);
        setTimeout(() => {
          refreshData();
        }, 1800000);
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  const DescriptionItem = ({ title }) => (
    <div
      className="site-description-item-profile-wrapper"
      style={{ fontFamily: FONT, fontSize: 16 }}
    >
      <b>{title}</b>
    </div>
  );

  function hasBackSquad(exercises) {
    return (
      exercises.filter((item) => item.planningType == "BACK_SQUAD").length > 0
    );
  }

  function getTranslationType(exercise) {
    var name =
      exercise.planningType == "ADDITIONAL_EXERCISE"
        ? exercise.additionalName
        : exercise.planningType == "NEW_EXERCISE"
        ? exercise.nameCvNew
        : translationsTypes[exercise.planningType];
    return name;
  }
  const Exercises = ({ exercises }) => {
    return (
      <div
        style={{
          maxHeight: 120,
          overflowY: "auto",
          overflowX: "auto",
        }}
      >
        {exercises.map((exercise, index) => (
          <Col style={{ minWidth: 450 }}>
            <Row key={index}>
              {" "}
              <Col span={8}>
                <DescriptionItem title={getTranslationType(exercise)} />
              </Col>
              <Col span={3}>
                <DescriptionItem title={exercise.load1 + "kg"} />
              </Col>
              <Col span={5}>
                <DescriptionItem
                  title={exercise.vmp1 != null ? exercise.vmp1 + " - VMP" : ""}
                />
              </Col>
              <Col span={6}>
                <DescriptionItem
                  title={
                    exercise.rm1 != null
                      ? exercise.planningType == "BARBELL_JUMP"
                        ? exercise.rm1 + " - ALT(CM)"
                        : exercise.rm1 + " - %1RM"
                      : ""
                  }
                />
              </Col>
            </Row>
          </Col>
        ))}
      </div>
    );
  };
  function getWeek(planningExercise) {
    const week = planningExercise[0]?.trainings[0]?.load;
    if (week) {
      return "Semana " + week;
    }
    return "Cíclo Finalizado";
  }
  const CardTrainings = ({ usersTrainings }) => {
    return usersTrainings.map((user) => {
      const [formUser] = Form.useForm();
      const planExercise = user.planningTrainings.filter(
        (p) => p.planningType == "BACK_SQUAD"
      );
      if (planExercise.length > 0) {
        const exercise = planExercise[0].trainings;
        if (exercise.length > 0) {
          formUser.setFieldValue("vmp1", exercise[0].vmp1);
          formUser.setFieldValue("vmp2", exercise[0].vmp2);
          formUser.setFieldValue("vmp3", exercise[0].vmp3);
          formUser.setFieldValue("vmp4", exercise[0].vmp4);
        }
      }
      return (
        <Col key={user.id} span={outerWidth < 800 ? 24 : 12}>
          <Card
            style={{
              width: "100%",
              marginBottom: 16,
              minHeight: 400,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            actions={getActionCard(user, formUser)}
          >
            <Meta
              avatar={
                <Avatar
                  src={user?.img}
                  icon={<UserRound size={80} />}
                  size={80}
                />
              }
              title={user.name + " - " + getWeek(user.planningTrainings)}
              description={
                <div>
                  <Row>
                    <Col span={9}>
                      <DescriptionItem title={"Exercícios"} />
                    </Col>
                  </Row>
                  <Exercises exercises={user.planningTrainings} />

                  {hasBackSquad(user.planningTrainings) && (
                    <>
                      <Row style={{ marginTop: 10 }}>
                        <Col span={24}>
                          <DescriptionItem title={"VMP Agachamento"} />
                        </Col>
                      </Row>
                      <Form form={formUser} layout="vertical">
                        <Row gutter={16}>
                          <Col span={6}>
                            <Form.Item name="vmp1">
                              <InputNumber
                                decimalSeparator=","
                                style={{ fontFamily: FONT, width: "100%" }}
                                onBlur={() => setVmp(formUser, user.planningId)}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name="vmp2">
                              <InputNumber
                                decimalSeparator=","
                                style={{ fontFamily: FONT, width: "100%" }}
                                onBlur={() => setVmp(formUser, user.planningId)}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name="vmp3">
                              <InputNumber
                                decimalSeparator=","
                                style={{ fontFamily: FONT, width: "100%" }}
                                onBlur={() => setVmp(formUser, user.planningId)}
                              />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item name="vmp4">
                              <InputNumber
                                decimalSeparator=","
                                style={{ fontFamily: FONT, width: "100%" }}
                                onBlur={() => setVmp(formUser, user.planningId)}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </div>
              }
            />
          </Card>
        </Col>
      );
    });
  };
  const formatTime = (date) => {
    if (!date) return "Nunca atualizado";
    return new Intl.DateTimeFormat("pt-BR", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    }).format(date);
  };
  const showDrawerDetail = () => {
    setOpenDetailItem(true);
  };
  function detail(row) {
    setExercise(row);
    showDrawerDetail();
  }
  const onCloseDetailItem = () => {
    setOpenDetailItem(false);
    setExercise(null);
  };

  function saveTrainingUser(user, formUser) {
    if (user.planningTrainings[0].trainings.length == 0) {
      notificationInfo("Aluno com cíclo finalizado");
      return;
    }
    const obj = {
      isDone: true,
      planningId: user.planningId,
      vmp1: formUser.getFieldValue("vmp1"),
      vmp2: formUser.getFieldValue("vmp2"),
      vmp3: formUser.getFieldValue("vmp3"),
      vmp4: formUser.getFieldValue("vmp4"),
    };
    saveTraining(obj)
      .then(() => {
        notificationSucessMsg("Treino finalizado com sucesso");
        refreshData();
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }

  function getActionCard(user, form) {
    if (hasBackSquad(user.planningTrainings)) {
      return [
        <div
          onClick={() => {
            setUserTrainer(user);
            setOpenChart(true);
          }}
        >
          <ChartColumnStacked />
        </div>,
        <div
          onClick={() => {
            setUserTrainer(user);
            setOpenDetail(true);
          }}
        >
          <Edit />
        </div>,
        <div onClick={() => saveTrainingUser(user, form)}>
          <Check />
        </div>,
      ];
    } else {
      return [
        <div
          onClick={() => {
            setUserTrainer(user);
            setOpenDetail(true);
          }}
        >
          <Edit />
        </div>,
        <div onClick={() => saveTrainingUser(user, form)}>
          <Check />
        </div>,
      ];
    }
  }
  function setVmp(formUser, planningId) {
    setLoading(true);
    const obj = {
      isDone: false,
      planningId: planningId,
      vmp1: formUser.getFieldValue("vmp1"),
      vmp2: formUser.getFieldValue("vmp2"),
      vmp3: formUser.getFieldValue("vmp3"),
      vmp4: formUser.getFieldValue("vmp4"),
    };
    saveTraining(obj)
      .then(() => {
        refreshData();
      })
      .catch((err) => {
        setLoading(false);
        notificationErroInternal(
          "Aconteceu um erro inesperado, por favor, tente mais tarde."
        );
      });
  }
  return (
    <div className="container-managerial">
      <Row gutter={24} style={{ padding: "10px" }}>
        <Space>
          <Button
            style={{
              fontFamily: FONT,
              background: "#09ac9c",
            }}
            type="primary"
            icon={
              <RotateCw
                size={18}
                style={{ marginBottom: "-3px", marginRight: "7px" }}
              />
            }
            onClick={refreshData}
          >
            Atualizar
          </Button>
        </Space>
      </Row>
      <Row>
        <div style={{ fontFamily: FONT, fontSize: 12, marginBottom: 15 }}>
          Última atualização: {formatTime(lastUpdated)}{" "}
        </div>
      </Row>
      <Spin spinning={loading}>
        <Row gutter={16}>
          <CardTrainings usersTrainings={data} />
        </Row>
      </Spin>

      <DrawerApp
        open={openDetail}
        onClose={onCloseDetail}
        title={"Detalhe do Ciclo"}
        body={""}
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      <DrawerApp
        open={openDetail}
        onClose={onCloseDetail}
        title={"Detalhe do Ciclo"}
        body={
          <PlanningExercises
            isOnlyView={true}
            exercises={
              userTrainer != null
                ? userTrainer.planningTrainings.map((item) => {
                    return {
                      ...item,
                      type: item.planningType,
                      cycle: item.cycleName?.replace(/\D/g, ""),
                      detail: detail,
                    };
                  })
                : []
            }
          />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      <DrawerApp
        open={openDetailItem}
        onClose={onCloseDetailItem}
        title={"Detalhe do Exercicio"}
        body={
          <FormNewUserPlanningExercise
            onClose={onCloseDetailItem}
            user={userTrainer}
            exercise={exercise}
          />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
      <DrawerApp
        open={openChart}
        onClose={onCloseChart}
        title={"Gráfico de Evolução"}
        body={<ManagerialChart onClose={onCloseChart} user={userTrainer} />}
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
    </div>
  );
};

export default Managerial;
