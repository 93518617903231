import React, { useEffect, useState } from "react";

import {
  Row,
  Input,
  Form,
  Col,
  DatePicker,
  Avatar,
  Spin,
  Space,
  Button,
} from "antd";
import { UserRound } from "lucide-react";

import { FONT } from "constants/index";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
  notificationErroInternal,
  notificationInfo,
  notificationSucess,
  notificationSucessMsg
} from "util/api-utils";
import { savePlanning } from "services/planning/planning-service";
import PlanningExercises from "./planning-drawer-exercises";
import FormNewUserPlanningExercise from "./planning-drawer-new-exercise";
import DrawerApp from "components/drawer-app";
dayjs.extend(customParseFormat);

const dateFormat = "DD/MM/YYYY";

const FormNewUserPlanning = (props) => {
  const [loadingData, setLoadingData] = useState(false);
  const [imgAvatar, setImgAvatar] = useState("");
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [exercise, setExercise] = useState(null);

  useEffect(() => {
    setLoadingData(true);
    setExercises([]);

    setImgAvatar(props.user?.img);
    form.resetFields();
    form.setFieldsValue({
      name: props.user?.name,
    });
    setLoadingData(false);
  }, [props]);

  const handleNewPlanning = () => {
    setLoadingData(true);

    form
      .validateFields()
      .then((values) => {
        values.userId = props.user.id;
        values.date = values.date.format(dateFormat);
        values.exercises = exercises;
        savePlanning(values)
          .then(() => {
            setLoadingData(false);
            notificationSucess();
            onCloseDrawer();
          })
          .catch(() => {
            notificationErroInternal(
              "Aconteceu um erro inesperado, por favor, tente mais tarde."
            );
            setLoadingData(false);
          });
      })
      .catch((errors) => {
        setLoadingData(false);
        console.log(errors);
      });
  };

  const onCloseDrawer = () => {
    props.onClose(props.user.id);
    setExercises([]);
  };

  const onClose = () => {
    setOpen(false);
    setExercise(null);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  function removeExercise(row) {
    const allExercise = exercises.filter((item) => item.id != row.id);
    setExercises(allExercise);
    notificationInfo("Exercíco removido com sucesso.");
  }
  function editExercise(row) {
    setExercise(row);
    showDrawer();
  }
  function onSaveExercise(exercise) {
    const hasExercice = exercises.filter((it) => it.type == exercise.type);
    if (hasExercice.length > 0 && exercise.isNew) {
      notificationInfo(
        "Exercíco já planejado. Se deseja editar, use a opção de edição da tabala de exercícios."
      );

      return;
    }

    const allExercise = [...exercises.filter((it) => it.id != exercise.id)];
    allExercise.push({
      ...exercise,
      remove: removeExercise,
      edit: editExercise,
    });
    setExercises(allExercise);
    notificationSucessMsg("Exercício adicionado na planificação");
  }

  return (
    <>
      <Spin spinning={loadingData}>
        <Form form={form} layout="vertical">
          <Row justify="end" style={{ marginBottom: "25px" }}>
            <Space>
              <Button style={{ fontFamily: FONT }} onClick={onCloseDrawer}>
                Cancelar
              </Button>
              <Button
                style={{ fontFamily: FONT }}
                onClick={handleNewPlanning}
                type="primary"
              >
                Salvar
              </Button>
            </Space>
          </Row>
          <Row
            gutter={16}
            style={{ textAlign: "center", marginBottom: "30px" }}
          >
            <Col span={24}>
              <Avatar
                src={imgAvatar}
                icon={<UserRound size={120} />}
                size={120}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={outerWidth < 501 ? 24 : 12}>
              <Form.Item
                name="name"
                label={<span style={{ fontFamily: FONT }}>Aluno</span>}
              >
                <Input disabled={true} style={{ fontFamily: FONT }} />
              </Form.Item>
            </Col>

            <Col span={outerWidth < 501 ? 24 : 12}>
              <Form.Item
                name="date"
                label={<span style={{ fontFamily: FONT }}>Data</span>}
                rules={[
                  {
                    required: true,
                    message: "É necessário informar a data da planificação",
                  },
                ]}
              >
                <DatePicker
                  style={{ fontFamily: FONT, width: "100%" }}
                  placeholder={["", ""]}
                  format={dateFormat}
                  suffixIcon={null}
                />
              </Form.Item>
            </Col>
          </Row>
          <PlanningExercises newExercise={showDrawer} exercises={exercises} />
        </Form>
      </Spin>
      <DrawerApp
        open={open}
        onClose={onClose}
        title={"Nova Exercicio"}
        body={
          <FormNewUserPlanningExercise
            onClose={onClose}
            user={props.user}
            onSave={onSaveExercise}
            exercise={exercise}
          />
        }
        size={"large"}
        isOnlyView={true}
        width={outerWidth < 501 ? "100%" : "65%"}
      />
    </>
  );
};
export default FormNewUserPlanning;
